import { API, removeBlankAttributes } from "@project/common"
import { UserManagementInitialValues } from "../types"

//
export type UserDataProps = { data: UserData[]; count: number }

export interface UserData {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  username: string
  furigana: string
  dob: string
  gender: string
  phone: string
  pobox: string
  pref_id: number
  address: string
  wage_style: string
  hourly_wage: number
  parent_id: any
  image: string
  financial_institution_name_kana: string
  branch_name_kana: string
  financial_institute_code: string
  branch_code: any
  account_type: any
  account_number: any
  account_holder_name: string
  customer_code: any
  email_address: string
  email_address_2: string
  attend_flag: boolean
  leave_flag: boolean
  emergency_contact_name1: string
  emergency_contact_phone1: string
  emergency_contact_name2: string
  emergency_contact_phone2: string
  emergency_contact_remarks: string
  contract_doc: string
  hospital_name: string
  doctor_name: string
  hospital_ph: string
  allergy_flag: boolean
  allergy_remarks: string
  symptoms: string
  medical_info: string
  other_service: string
  note_book_grade_flag: boolean
  note_book_grade: number
  disability_grade_text: string
  disability_grade: number
  family_structure: string
  remarks: string
  things_of_interest: string
  thing_to_watchout: string
  email: string
  user_certificate: UserCertificate
  active_contract: any
  certificate_number?: string
  service_type?: number
  facility_id?: number
}

export interface UserCertificate {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  user_id: number
  user: User
  start_date: string
  certificate_original: string
  number: string
  provision_city_id: number
  service_type: number
  physical_disability_flag: boolean
  intellectual_disability_flag: boolean
  mental_disability_flag: boolean
  development_disability_flag: boolean
  brain_disfunction_flag: boolean
  disabled_child_support_flag: boolean
  incurable_disease_flag: boolean
  benefit_payment_amount: number
  application_period_input_flag: boolean
  benefit_start_date: any
  benefit_end_date: any
  provision_start_date: any
  provision_end_date: any
  consultation_support_office_id: any
  consultation_support_office: ConsultationSupportOffice
  consultation_support_specialist: any
  burden_upper_limit_monthly_amount: any
  applicable_start_date: any
  applicable_end_date: any
}

export interface User {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  company_id: number
  username: string
  furigana: string
  dob: string
  gender: string
  phone: string
  pobox: string
  pref_id: number
  address: string
  wage_style: string
  hourly_wage: number
  parent_id: any
  image: any
  financial_institution_name_kana: any
  branch_name_kana: string
  financial_institute_code: any
  branch_code: any
  account_type: any
  account_number: any
  account_holder_name: any
  customer_code: any
  email_address: any
  email_address_2: any
  attend_flag: any
  leave_flag: any
  emergency_contact_name1: any
  emergency_contact_phone1: any
  emergency_contact_name2: any
  emergency_contact_phone2: any
  emergency_contact_remarks: any
  contract_doc: string
  hospital_name: any
  doctor_name: any
  hospital_ph: any
  allergy_flag: any
  allergy_remarks: any
  symptoms: any
  medical_info: any
  other_service: any
  note_book_grade_flag: any
  note_book_grade: number
  disability_grade_text: any
  disability_grade: number
  family_structure: any
  remarks: any
  things_of_interest: any
  thing_to_watchout: any
  email: string
  user_certificate: any
  active_contract: any
}

export interface ConsultationSupportOffice {
  id: number
  created_datetime: string
  created_by: string
  updated_datetime: string
  updated_by: string
  deleted_datetime: any
  deleted_by: string
  is_deleted: boolean
  consultation_support_name: string
  consultation_support_name_hiragana: string
  consultation_support_number: string
  post_box_number: string
  address: string
  phone_number: string
  fax_number: string
  pref_id: number
}

export const saveUser = async (values: UserManagementInitialValues) => {
  return API.post(`/user`, values)
}

export const updateUser = async ({
  values,
  id,
}: {
  values: UserManagementInitialValues
  id: string | number
}) => {
  return API.put(`/user/${id}`, { ...values })
}

export const fetchOneUser = async (
  id: number | string,
): Promise<UserManagementInitialValues> => {
  const response = await API.get(`/user/${id}`)
  return response.data
}

export const getAllUsers = async (): Promise<UserDataProps> => {
  return API.get(`/user?page=1&pageSize=Infinity`)
}

export const fetchUserByUserId = async (childId): Promise<UserDataProps> =>
  API.get(`/certificate?${childId}`)

export const getFacilityUsers = async ({
  ...args
}: {
  page: number
  pageSize: string
  year: number
  month: number
  facilityIds?: string
}) => {
  const params = removeBlankAttributes(args)
  return API.get(`/user`, { params })
}

export const deleteUserDetails = async (id) => {
  return API.delete(`/user/${id}`)
}
