import { theme } from "@project/common"
import styled from "styled-components"

export const Container = styled.div<{ isBreadCrumbMargin?: boolean }>`
  background: ${theme.colors.background};
  padding: 40px;
  padding-top: 0;
  padding-bottom: 100px;

  .child-detail-header {
    justify-content: flex-start;
    & > div:first-child {
      margin-right: 15px;
    }
    flex-wrap: wrap;
  }

  .ant-breadcrumb {
    margin-bottom: ${(props) => (props.isBreadCrumbMargin ? "27px" : "0px")};
  }
  @media (max-width: 768px) {
    padding: 10px;
    button {
      white-space: pre-wrap;
      word-break: break-all;
      line-height: 18px;
    }
  }
  @media print {
    background: #fff;
    padding: 0px;
    @page {
      margin: 20mm 10mm !important;
      /* I changed it to unset because I think its
      better for user to set the size and give more control
      also HUG does it the same way.
      Please let me know if you have any concerns 🙇🏻‍♂️
      */
      size: unset;
    }
    html,
    body {
      width: 210mm;
      height: auto;
      font-size: 10px;
      background: #fff;
      overflow: visible;
    }
  }
  .extra-btn {
    min-width: 190px;
  }
`
export const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;

  position: relative;
  .overlay-loading {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 9;
  }

  @media screen and (max-width: 426px) {
    .label-container {
      padding: 12px !important;
    }
  }

  .burden_div {
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 426px) {
      gap: 0;
      span {
        margin-left: 10px !important;
      }
    }
  }

  .inner-grid-content {
    width: 100%;
  }
  .spacial-skill__field-wrapper {
    border-top: 1px solid ${theme.colors.border} !important;
  }

  .grid-header {
    width: 300px;
    padding: 28px 12px 28px 18px;
  }

  @media screen and (max-width: 426px) {
    .ant-select-arrow {
      display: block;
    }

    .short-lbl:not(:empty) {
      display: block;
    }

    .short-lbl:empty {
      display: none;
    }
  }

  .grid-header.label-container {
    width: 25.42%;
    max-width: 300px;
    min-width: 100px;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
    }
  }

  .label-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 426px) {
      column-gap: 8px;
    }
  }

  .label-wrapper.recorder-label-content {
    justify-content: start;
  }

  .right-column {
    width: 100%;
    padding: 28px 55px 28px 18px;
    .label-wrapper {
      @media (max-width: 1038px) {
        flex-direction: column;
      }
    }
  }

  .recorder_field-wrapper {
    row-gap: 20px;
    @media (max-width: 1232px) {
      justify-content: start;
    }
  }

  .label-wrapper__container {
    flex: 1;
    padding: 20px;
    @media (max-width: 1038px) {
      flex: none;
      width: 100%;
    }
    @media (max-width: 767px) {
      border-right: 1px solid ${theme.colors.border} !important;
      border-left: 1px solid ${theme.colors.border};
    }
  }
  .label-wrapper__container:nth-child(1) {
    border-right: 1px solid ${theme.colors.border};
    @media (max-width: 1038px) {
      border-right: none;
      border-bottom: 1px solid ${theme.colors.border};
    }
  }
  input,
  textarea {
    background-color: #ffffff;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    .grid-header {
      width: 100%;
      padding: 10px;
    }
  }

  .text-required {
    color: #e00;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
  }
  .text-link {
    color: #0782c8;
    text-decoration: underline;
    display: inline-flex;
    margin-right: 15px;
  }

  .text-field-headertext {
    margin-top: 20px;
  }

  .button-wrapper {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .cancel-btn {
      margin-right: 17px;
    }

    @media screen and (max-width: 426px) {
      margin-top: 32px;
    }

    @media (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .cancel-btn {
        margin-right: 10px;
      }
    }
  }
  .button-wrapper.joint-facility-form-btns {
    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 16px;
    }
  }

  .button-wrapper.auto-email_form-btns-wrapper {
    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 0;
    }
  }

  .child-registration-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px;
    .delete-child {
      background: ${theme.colors.error};
      border-color: ${theme.colors.error};
    }
    @media (max-width: ${theme.breakpoints.sm}) {
      justify-content: center;
      display: grid;
    }
  }

  .ant-radio.ant-radio-checked .ant-radio-inner::after {
    background: #0782c8;
  }
  .title {
    white-space: pre-wrap;
    word-break: break-all;
  }

  .gray-bg {
    background-color: ${theme.colors.background};
    padding: 4px 10px;
    display: inline-block;
  }
  .whitespace-class {
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (max-width: 410px) {
      white-space: pre-line;
    }
  }
  .multiple-buttons-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    gap: 10px;
    flex-wrap: wrap;
    .share-with-parent-btn-sm {
      display: none;
    }
    .separate-btns-block {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 10px;
      @media (max-width: 375px) {
        justify-content: center;
      }
    }
    .today-event-sm-contents {
      flex-wrap: nowrap;
      gap: 10px;
    }

    @media (max-width: 375px) {
      flex-direction: column;
      justify-content: center;
      .share-with-parent-btn-sm {
        display: block;
      }
      .share-with-parent-btn-lg {
        display: none;
      }
    }
  }

  /* this styling is over-riding predefined styling for button
   [NOTE: this approach is not recommended] */
  .wrappable-button {
    white-space: normal;

    @media screen and (max-width: 426px) {
      padding: 12px 5px;
      width: 100%;
    }
  }
  .sm-size-btn {
    @media screen and (max-width: 443px) {
      padding: 12px 2px !important;
      line-height: 18px !important;
      font-size: 12px !important;
      width: 100% !important;
    }
  }

  .service-provision-form-btns {
    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 16px;
    }
  }
`
export const MultiButtonsWrapper = styled.div`
  &.multiple-buttons-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    gap: 10px;
    flex-wrap: wrap;
    .share-with-parent-btn-sm {
      display: none;
    }

    @media screen and (max-width: 376px) {
      .draft-btn,
      .share-with-parent-btn-sm {
        padding: 12px 22px !important;
      }
    }

    .separate-btns-block {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 30px;
      row-gap: 10px;
      @media (max-width: 450px) {
        justify-content: center;
      }
    }
    .today-event-sm-contents {
      flex-wrap: nowrap;
      gap: 10px;
      margin-bottom: 15px;
    }

    @media (max-width: 450px) {
      flex-direction: column;
      justify-content: center;
      .share-with-parent-btn-sm {
        display: block;
      }
      .share-with-parent-btn-lg {
        display: none;
      }
    }
  }
`
export const Info = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444748;
  margin-top: 9px;
  white-space: pre-line;
  @media (max-width: 767px) {
    margin-top: 4px;
  }
`
export const FormTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  &.centered-contents {
    justify-content: center;
  }

  &.joint-facility-header {
    margin-top: 0px;
  }

  @media print {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px 0;
    background-color: #eef1f4;

    .form-title {
      margin-top: 0;
      padding-left: 10px;
      .ant-typography {
        font-size: 16px;
      }
    }
  }

  &.gap-mobile {
    padding-top: 2%;
  }
  .beneficiary-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media (max-width: 450px) {
    display: flex;
    flex-direction: row;

    align-items: flex-start;
    margin-top: 0px;
  }
  &.operation-header {
    margin-top: 0px;
  }
  .flex-row-big {
    display: flex;
    align-items: center;
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0;
      padding-top: 10px;
    }
    .title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      min-width: 110px;
    }
    .red {
      @media (max-width: 767px) {
        margin-left: 0 !important;
      }
    }
  }
`

export const Divisor = styled.div`
  border-top: 1px solid #d2d1d1;
  margin-bottom: 20px;
  margin-top: 12px;
  width: 100%;
  @media print {
    display: none;
  }
`
export const HorizontalInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .info {
    margin-left: 21px;
    margin-top: 0;
  }

  &.targeted-fiscal-year-input {
    gap: 0 !important;
    .info {
      margin-left: 10px !important;
      margin-top: 0;
    }
  }
  input {
    width: 240px;
  }
  @media (max-width: 1085px) {
    flex-direction: column;
    align-items: flex-start;
    .info {
      margin-left: 0;
      margin-top: 9px;
    }
    .half-field {
      width: 100%;
    }
    input {
      width: 100% !important;
    }
  }
`

/**
 * few common styling for restricted route pages
 * like login, forget-password, reset-password
 *
 *
 */

export const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #ffff;
  border-radius: 10px;
  .login-btn-inside {
    margin-bottom: 44px;
    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }
  }
  .login-btn {
    width: 165px;
    height: 35px;

    @media (max-width: 1024px) {
      width: 140px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  @media (max-width: 1024px) {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    width: 100%;
    margin-bottom: 50px;
  }
`

export const SuccessText = styled.div`
  border-radius: 10px;
  background-color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 1rem;
  width: 406px;
  @media (max-width: 420px) {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  .flex-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap !important;
    row-gap: 1rem;
  }
  .flex-row-maximum {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    row-gap: 1rem;
  }
  .add-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .short-lbl {
    margin-left: 9px;
    margin-right: 7px;
  }
  .date-card-wrapper {
    margin-bottom: 30px;
    .ant-card {
      margin-top: 0px !important;
    }
  }
  @media (max-width: 940px) {
    .flex-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .flex-row-maximum {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .short-lbl {
      margin: 5px 5px;
    }
  }

  @media (max-width: 768px) {
    .full-width-select {
      width: calc(100vw - 156px);
      max-width: 250px;
    }
  }

  @media (max-width: 426px) {
    .full-width-select {
      margin-left: 4px;
    }
  }
`

export const Containeer = styled.div`
  background: ${theme.colors.background};
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 20px;
  flex: 1;
  .top-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      margin: 17px 0;
      font-size: 18px;
      font-weight: 500;
    }
    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 133px;
      width: 133px;
      background: white;
      border-radius: 50%;
    }
  }
`

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.border};
  background: ${theme.colors.white};
  padding: 1rem 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  height: auto;
  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
    min-width: 200px;
  }
`

export const TextFieldWrapper = styled.div`
  margin-top: 35px;
  .forget-password-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 27px;
    p {
      margin: auto 0px;
      font-size: 14px;
    }
    a {
      text-decoration: underline;
      margin-left: 5px;
      font-size: 14px;
    }
  }
  .login-button-wrapper {
    text-align: center;
  }
  .password-info-text {
    font-size: 8px;
    color: ${theme.colors["sub-text"]};
  }
`

export const InputFieldWrapper = styled.div`
  margin-bottom: 25px;
  @media (max-width: ${theme.breakpoints.sm}) {
    .text-field {
      width: 100%;
      /* max-width: 340px; */
    }
  }
`
export const Highlighter = styled.div`
  background: #0782c8;
  width: 3px;
  height: 25px;
  margin-right: 8px;
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 37px;
  color: #191919;
  @media (max-width: 456px) {
    font-size: 16px;
  }
`
export const Detail = styled.div`
  font-size: 16px;
  color: #191919;
  margin-bottom: 30px;
`
export const TagWrapper = styled.div`
  margin-bottom: 10px;
`
export const ErrorMessage = styled.div`
  font-size: 12px;
  color: ${theme.colors.error};
  margin-top: 2px;
  margin-left: 2px;
`
export const StyledMonthPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background: white;
    color: black;
    border: 1px solid #d2d1d1;
    border-radius: 10px;
    height: 40px;
    margin-right: 10px;
    padding: 0px;
    min-width: 75px;
    &.active {
      background: rgba(7, 130, 200, 0.2);
    }
  }
`

export const DataContainer = styled.div<{ isNoBoxShadow?: boolean }>`
  background: #ffffff;
  box-shadow: ${(props) =>
    props?.isNoBoxShadow
      ? "none !important"
      : " 0px 0px 15px rgba(0, 0, 0, 0.04)"};
  border-radius: 12px;
  padding: 11px 30px 27px 30px;

  @media (max-width: 768px) {
    padding: 11px 16px 27px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media print {
    padding: 0;
    margin: 0;
    @page {
      margin: 1cm 0cm;
    }
    background-color: none;
    box-shadow: none;
    border-radius: none;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    &.contract-report-block {
      padding: 15px 19px;
    }
  }
`
