import {
  CheckBox,
  Grid,
  InputAreaField,
  InputField,
  RadioGroup,
  SelectInput,
} from "@project/common"
import { t } from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"
import { styled } from "styled-components"
import { YES_NO_LIST } from "../../../../../constants"
import { UserComponentFormProps } from "../../../../../types"
import { Divisor } from "../../../../../utils/shared-style"
import { convertToHalfWidth } from "@project/common/src/constants"
const Container = styled.div`
  margin-top: 28px;
  .grid_last {
    border-bottom: 1px solid #d2d1d1 !important;
  }
  .last_form {
    margin-top: 28px;
  }
`
const SeparateContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  > div:first-child {
    width: 320px;
  }
`

const AllergyContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  > div {
    width: 100%;
  }
`
export const disability_notebook_grade_options = [
  { label: t("Physical disability certificate"), value: 1 },
  { label: t("Rehabilitation certificate"), value: 2 },
  { label: t("Health Benefits certificate for the Mentally"), value: 3 },
]

const DisabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  .header {
    display: flex;
    gap: 48px;

    @media screen and (max-width: 502px) {
      flex-wrap: wrap;
      gap: 12px;
    }
  }
  .grade {
    margin-bottom: 6px;
  }
`

const OtherInformation: React.FC<UserComponentFormProps> = ({
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur,
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <span>{t("Other Information")}</span>
      <Divisor />
      <Grid
        labelContent={t("Allergy")}
        className={"row__header"}
        background
        labelSpan={6}
      >
        <AllergyContainer>
          <CheckBox
            name={"allergy_flag"}
            label={t("None")}
            value={values?.allergy_flag}
            checked={values?.allergy_flag}
            onChange={({ target: { checked } }) =>
              setFieldValue("allergy_flag", checked)
            }
          />

          <InputField
            name={"allergy_remarks"}
            className={"hourly_wage"}
            id={"allergy_remarks"}
            placeholder={t("例：卵、牛乳、小麦、ナッツ、えび")}
            padding={"8px"}
            borderRadius={"4px"}
            maxLength={200}
            info={t("(within 200 char)")}
            disabled={values?.allergy_flag === true}
            value={values?.allergy_remarks}
            onChange={(e) => setFieldValue("allergy_remarks", e.target.value)}
          />
        </AllergyContainer>
      </Grid>
      <Grid
        labelContent={t("Symptoms")}
        className={"row__header"}
        background
        labelSpan={6}
      >
        <InputAreaField
          name={"symptoms"}
          id={"symptoms"}
          placeholder={t(" 例：アスペルガー症候群,軽度精神遅滞障害")}
          padding={"8px"}
          borderRadius={"4px"}
          rows={4}
          value={values?.symptoms}
          onChange={(e) => setFieldValue("symptoms", e.target.value)}
        />
      </Grid>
      <Grid
        labelContent={t("Medical Information")}
        className={"row__header"}
        background
        labelSpan={6}
      >
        <InputAreaField
          name={"medical_info"}
          id={"medical_info"}
          placeholder={t("--")}
          padding={"8px"}
          borderRadius={"4px"}
          rows={4}
          value={values?.medical_info}
          onChange={(e) => setFieldValue("medical_info", e.target.value)}
        />
      </Grid>
      <Grid
        labelContent={t("Other service which user is using")}
        className={"row__header"}
        background
        labelSpan={6}
      >
        <InputAreaField
          name={"other_service"}
          id={"other_service"}
          placeholder={t("--")}
          padding={"8px"}
          borderRadius={"4px"}
          rows={4}
          value={values?.other_service}
          onChange={(e) => setFieldValue("other_service", e.target.value)}
        />
      </Grid>
      <Grid
        labelContent={t("Disability Notebook Grade")}
        className={"row__header"}
        background
        labelSpan={6}
      >
        <DisabilityContainer>
          <div className={"header"}>
            <RadioGroup
              options={YES_NO_LIST}
              value={values?.note_book_grade_flag === true ? 1 : 2}
              name={"note_book_grade_flag"}
              onChange={(e) => {
                if (e.target.value === 1) {
                  setFieldValue("note_book_grade_flag", true)
                  setFieldValue("note_book_grade", 1)
                } else {
                  setFieldValue("note_book_grade_flag", false)
                }
                if (e.target.value === 2) {
                  setFieldValue("note_book_grade", 1)
                  setFieldValue("disability_basic_pension", null)
                }
              }}
            />
            <CheckBox
              name={"disability_basic_pension"}
              label={t("Disability basic pension class 1")}
              value={values?.disability_basic_pension}
              checked={values?.disability_basic_pension}
              onChange={({ target: { checked } }) =>
                setFieldValue("disability_basic_pension", checked)
              }
              disabled={!values?.note_book_grade_flag}
            />
          </div>

          <>
            <SelectInput
              name={"facility"}
              options={disability_notebook_grade_options}
              width={"400px"}
              disabled={!values?.note_book_grade_flag}
              value={values?.note_book_grade || 1}
              onChange={(value) => {
                setFieldValue("note_book_grade", +value)
              }}
            />
            <div>
              {values?.note_book_grade_flag && +values?.note_book_grade > 0 ? (
                <div className={"grade"}>{t("Grade1:")}</div>
              ) : (
                <></>
              )}
              {values?.note_book_grade_flag &&
                +values?.note_book_grade === 1 && (
                  <RadioGroup
                    disabled={!values?.note_book_grade_flag}
                    value={values.disability_grade}
                    onChange={(e) =>
                      setFieldValue("disability_grade", +e.target.value)
                    }
                    options={[
                      {
                        label: t("1"),
                        value: 1,
                      },
                      {
                        label: t("2"),
                        value: 2,
                      },
                      {
                        label: t("3"),
                        value: 3,
                      },
                      {
                        label: t("4"),
                        value: 4,
                      },
                      {
                        label: t("5"),
                        value: 5,
                      },
                      {
                        label: t("6"),
                        value: 6,
                      },
                      {
                        label: t("7"),
                        value: 7,
                      },
                    ]}
                  />
                )}
              {values?.note_book_grade_flag &&
                +values?.note_book_grade === 2 && (
                  <InputField
                    name={"disability_grade_text"}
                    disabled={!values?.note_book_grade_flag}
                    className={"hourly_wage"}
                    id={"disability_grade_text"}
                    placeholder={""}
                    padding={"8px"}
                    borderRadius={"4px"}
                    value={values?.disability_grade_text}
                    onChange={(e) =>
                      setFieldValue("disability_grade_text", e.target.value)
                    }
                  />
                )}
              {values?.note_book_grade_flag &&
                +values?.note_book_grade === 3 && (
                  <RadioGroup
                    disabled={!values?.note_book_grade_flag}
                    value={values.disability_grade}
                    onChange={(e) =>
                      setFieldValue("disability_grade", +e.target.value)
                    }
                    options={[
                      {
                        label: t("1"),
                        value: 1,
                      },
                      {
                        label: t("2"),
                        value: 2,
                      },
                      {
                        label: t("3"),
                        value: 3,
                      },
                    ]}
                  />
                )}
            </div>
          </>
        </DisabilityContainer>
      </Grid>
      <Grid
        labelContent={t("Family structure")}
        className={"row__header"}
        background
        labelSpan={6}
      >
        <InputAreaField
          name={"family_structure"}
          id={"family_structure"}
          placeholder={t("例：父、母、兄")}
          padding={"8px"}
          borderRadius={"4px"}
          maxLength={200}
          info={t("(within 200 char)")}
          rows={4}
          value={values?.family_structure}
          onChange={(e) => setFieldValue("family_structure", e.target.value)}
        />
      </Grid>
      <Grid
        labelContent={t("Remarks")}
        className={"row__header"}
        background
        labelSpan={6}
      >
        <InputAreaField
          name={"remarks"}
          id={"remarks"}
          placeholder={t("例：てんかん、睡眠障害")}
          padding={"8px"}
          borderRadius={"4px"}
          maxLength={200}
          info={t("(within 200 char)")}
          rows={4}
          value={values?.remarks}
          onChange={(e) => setFieldValue("remarks", e.target.value)}
        />
      </Grid>
      <Grid
        labelContent={t("Special skill· thing of interest")}
        className={"row__header"}
        background
        labelSpan={6}
      >
        <InputAreaField
          name={"things_of_interest"}
          id={"things_of_interest"}
          placeholder={t("例：ぬり絵などをやらせると気分が落ち着きやすい")}
          padding={"8px"}
          borderRadius={"4px"}
          rows={4}
          value={values?.things_of_interest}
          onChange={(e) => setFieldValue("things_of_interest", e.target.value)}
        />
      </Grid>
      <Grid
        labelContent={t("Things to watch out for")}
        className={"row__header grid_last"}
        background
        labelSpan={6}
      >
        <InputAreaField
          name={"thing_to_watchout"}
          id={"thing_to_watchout"}
          placeholder={t("例：環境の変化に敏感なので事前に予告する")}
          padding={"8px"}
          borderRadius={"4px"}
          rows={4}
          value={values?.thing_to_watchout}
          onChange={(e) => setFieldValue("thing_to_watchout", e.target.value)}
        />
      </Grid>
      <div className={"last_form"}>
        <Grid
          labelContent={t("My page login beneficiary certificate No. or email")}
          className={"row__header"}
          background
          required
          labelSpan={6}
        >
          <SeparateContainer>
            <InputField
              name={"email"}
              id={"email"}
              placeholder={t(" 例：someone@example.com")}
              padding={"8px"}
              borderRadius={"4px"}
              maxLength={50}
              onBlur={handleBlur}
              value={values?.email}
              onChange={(e) =>
                setFieldValue("email", convertToHalfWidth(e.target.value))
              }
              error={touched?.email && errors?.email ? errors?.email : ""}
            />
            <span>{t("(半角英数字6文字以上50字未満)")}</span>
          </SeparateContainer>
        </Grid>
        <Grid
          labelContent={t("Set password")}
          className={"row__header"}
          background
          required
          labelSpan={6}
        >
          <SeparateContainer>
            <InputField
              name={"password"}
              id={"password"}
              placeholder={t("例：abcd0123")}
              padding={"8px"}
              borderRadius={"4px"}
              maxLength={16}
              onBlur={handleBlur}
              value={values?.password}
              onChange={(e) =>
                setFieldValue("password", convertToHalfWidth(e.target.value))
              }
              error={
                touched?.password && errors?.password ? errors?.password : ""
              }
            />
            <span>{t("(半角英数字6文字以上16字未満)")}</span>
          </SeparateContainer>
        </Grid>
      </div>
    </Container>
  )
}

export { OtherInformation }
